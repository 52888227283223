export default class Helper {
	static titleCase(text) {
		return text.replace(/([a-z])([A-Z])/g, '$1 $2');
	}

	static snakeToTitleCase(text) {
		let result = text.split("_");

		result = result.map(t => t[0].toUpperCase().concat(t.substring(1, t.length))).join(" ");

		return result;
	}

	static validateTel = (tel) => {
		let pattern = new RegExp(/^(\+?6?0)[0-46-9]-*[0-9]{7,8}$/g);

		if (!pattern.test(tel)) {
			return false;
		}

		return true;
	}

	static validatePostcode = (postcode) => {
		let pattern = new RegExp(/^\d{5}$/g);

		if (!pattern.test(postcode)) {
			return false;
		}

		return true;
	}

	static validateEmail = (email) => {
		let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

		if (!pattern.test(email)) {
			return false;
		}

		return true;
	}

	static validateFileToUpload = (_file, _format) => {
		if(_file && _file.type.includes(_format))
			return true
		else return false
	}

	static validateAndRemoveUnwantedTags = (_val) => {
		return _val && typeof _val === 'string' && _val.replace(/<[^>]*>/g, '');
	}
}